export const routes_signup = "/signup";
export const routes_login = "/login";
export const routes_terms = "/terms";
export const routes_set_password = "/set-password";
export const routes_forgot_password = "/forgot-password";
export const routes_resend = "/resend";
export const routes_dashboard = "/dashboard/";
export const routes_customers = '/dashboard/customers';
export const routes_users = '/dashboard/users';
export const routes_subscriptions = '/dashboard/subscriptions';
export const routes_settings = '/dashboard/settings';
export const customers = '/customers';
export const users = '/users';
export const subscriptions = '/subscriptions';
export const settings = '/settings';