import {
    ContainerOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Layout, Menu, Breadcrumb, Dropdown } from "antd";
import React, { useState, useMemo } from "react";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import profile from "../../assets/ellipse.png";
import ivoryLogo from "../../assets/ivory-logo.png";
import Customers from '../customers/Customers';
import Users from '../users/Users';
import "./Home.css";
import Subscriptions from '../subscriptions/Subscriptions';
import Settings from '../settings/Settings';
import { customers, routes_customers, routes_settings, routes_subscriptions, routes_users, settings, subscriptions, users } from '../../utils/RoutingConsts';

const { Header, Content, Sider } = Layout;

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleLogout = () => {
        navigate('/login');
    };

    const userMenu = (
        <Menu>
            <Menu.Item key="1" onClick={handleLogout}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    const breadcrumbMap = useMemo(() => ({
        [routes_customers]: 'CUSTOMERS',
        [routes_users]: 'USERS',
        [routes_subscriptions]: 'SUBSCRIPTIONS',
        [routes_settings]: 'SETTINGS'
    }), []);

    const breadcrumbItems = useMemo(() => {
        const path = location.pathname; 
        const breadcrumbLabel = breadcrumbMap[path] || '';
        return [
            { title: 'DASHBOARD' },
            { title: breadcrumbLabel }
        ];
    });

    return (
        <Layout className="home-layout">
            <Header className="top-navbar">
                <img className="logo" src={ivoryLogo} alt="Ivory Logo" />
                <div className="user">
                    <div className="user-profile">
                        <span className="user-name">Pranav Prasad</span>
                        <span className="user-role">Administrator</span>
                    </div>
                    <Dropdown overlay={userMenu} trigger={['click']}>
                        <img className="profile-pic" src={profile} alt="Profile Picture" />
                    </Dropdown>
                </div>
            </Header>
            <Layout className='home-content'>
                <Sider className="side-navbar" collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
                    <Menu
                        defaultSelectedKeys={['1']}
                        mode="inline"
                        inlineCollapsed={collapsed}
                        className='side-navbar-menu'
                    >
                        <Menu.Item key="1" icon={<UserOutlined  style={{fontSize:22}}/>}>
                            <Link to={routes_customers}>Customers</Link>
                        </Menu.Item>
                        <Menu.Item key="2" icon={<TeamOutlined  style={{fontSize:22}}/>}>
                            <Link to={routes_users}>Users</Link>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<ContainerOutlined  style={{fontSize:22}}/>}>
                            <Link to={routes_subscriptions}>Subscriptions</Link>
                        </Menu.Item>
                        <Menu.Item key="4" icon={<SettingOutlined  style={{fontSize:22}}/>}>
                            <Link to={routes_settings}>Settings</Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Content className='content'>
                        <Breadcrumb className='breadcrumb' items={breadcrumbItems} /> {/* Dynamic breadcrumb */}
                        <Routes>
                            <Route path="/" element={<Navigate to={routes_customers} />} />
                            <Route path={customers} element={<Customers />} />
                            <Route path={users} element={<Users />} />
                            <Route path={subscriptions} element={<Subscriptions />} />
                            <Route path={settings} element={<Settings />} />
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Home;