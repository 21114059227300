import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import { AuthContextProvider } from "./context_api/ContextApi";
import Login from "./login/Login";
import Home from "./components/home/Home";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={ <Navigate to="/login" /> }></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/dashboard/*"  element={<Home />}></Route>
        </Routes>

      </BrowserRouter>
    </AuthContextProvider>);
}

export default App;
