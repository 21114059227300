import { createContext, useContext, useState } from "react";

const UserContext = createContext();
export const AuthContextProvider = ({ children }) => {
    const [spinner, setSpinner] = useState(false);
    return (
        <UserContext.Provider
            value={{
                spinner,
                setSpinner,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
export const UserAuth = () => {
    return useContext(UserContext);
};