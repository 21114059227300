import { Helmet } from "react-helmet";

const Users = () => {
  return (
    <div>
      <Helmet>
        <title>Users Dashboard</title>
      </Helmet>
      Users
    </div>
  );
};

export default Users;
