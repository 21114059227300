import { Helmet } from "react-helmet";

const Settings = () => {
  return (
    <div className="settings">
      <Helmet>
        <title>Settings Dashboard</title>
      </Helmet>
      Settings
    </div>
  );
};

export default Settings;
